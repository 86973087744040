import React from 'react';
// import {Link} from 'react-router-dom';

import './style.scss';

function Home() {
  return (
    <main>
      <div className="wrapper">
        <p>
          Espace de gestion de commande
        </p>
      </div>
    </main>
  );
}

export default Home;