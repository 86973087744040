// => SUSHI :
export const sushiDatas = [
    {
        id: '1',
        label: 'Sushi',
        name: 'Thon',
        price: 3.60,
        nombre: 2,
        done: true,
    },
    {
        id: '2',
        label: 'Sushi',
        name: 'Saumon',
        price: 3.90,
        nombre: 2,
        done: true,
    },
    {
        id: '3',
        label: 'Sushi',
        name: 'Crevette',
        price: 4.10,
        nombre: 2,
        done: true,
    },
    {
        id: '4',
        label: 'Sushi',
        name: 'Saumon avocat',
        price: 4.10,
        nombre: 2,
        done: true,
    },
    {
        id: '5',
        label: 'Sushi',
        name: 'Saumon cheese',
        price: 3.90,
        nombre: 2,
        done: true,
    },
    {
        id: '6',
        label: 'Sushi',
        name: 'Saumon braisé',
        price: 3.70,
        nombre: 2,
        done: true,
    },
    {
        id: '7',
        label: 'Sushi',
        name: 'Thon braisé',
        price: 4.10,
        nombre: 2,
        done: true,
    },
    {
        id: '8',
        label: 'Maki',
        name: 'Thon',
        price: 4.50,
        nombre: 6,
        done: true,
    },
    {
        id: '9',
        label: 'Maki',
        name: 'Saumon',
        price: 4.20,
        nombre: 6,
        done: true,
    },
    {
        id: '10',
        label: 'Maki',
        name: 'Saumon avocat',
        price: 4.50,
        nombre: 6,
        done: true,
    },
    {
        id: '11',
        label: 'Maki',
        name: 'Crevette avocat',
        price: 4.50,
        nombre: 6,
        done: true,
    },
    {
        id: '12',
        label: 'Maki',
        name: 'Concombre',
        price: 3.60,
        nombre: 6,
        done: true,
    },
    {
        id: '13',
        label: 'Maki',
        name: 'Avocat cheese',
        price: 4.00,
        nombre: 6,
        done: true,
    },
    {
        id: '14',
        label: 'Maki',
        name: 'Concombre cheese',
        price: 3.90,
        nombre: 6,
        done: true,
    },
    {
        id: '15',
        label: 'Maki',
        name: 'Avocat',
        price: 3.70,
        nombre: 6,
        done: true,
    },
    {
        id: '17',
        label: 'California',
        name: 'Saumon',
        price: 4.70,
        nombre: 6,
        done: true,
    },
    {
        id: '18',
        label: 'California',
        name: 'Thon',
        price: 4.80,
        nombre: 6,
        done: true,
    },
    {
        id: '19',
        label: 'California',
        name: 'Crevette',
        price: 4.80,
        nombre: 6,
        done: true,
    },
    {
        id: '20',
        label: 'California',
        name: 'Saumon fumé cheese',
        price: 4.70,
        nombre: 6,
        done: true,
    },
    {
        id: '21',
        label: 'California',
        name: 'Chicken Pané',
        price: 5.60,
        nombre: 6,
        done: true,
    },
    {
        id: '22',
        label: 'California',
        name: 'Caesar',
        price: 6.80,
        nombre: 8,
        done: true,
    },
    {
        id: '23',
        label: 'GreenRolls',
        name: 'Saumon',
        price: 5.20,
        nombre: 6,
        done: true,
    },
    {
        id: '24',
        label: 'GreenRolls',
        name: 'Thon',
        price: 5.40,
        nombre: 6,
        done: true,
    },
    {
        id: '25',
        label: 'GreenRolls',
        name: 'Crevettes Tempura',
        price: 5.90,
        nombre: 6,
        done: true,
    },
    {
        id: '26',
        label: 'GreenRolls',
        name: 'Végétarien',
        price: 4.70,
        nombre: 6,
        done: true,
    },
    {
        id: '27',
        label: 'GreenRolls',
        name: 'Chicken Pané',
        price: 5.90,
        nombre: 6,
        done: true,
    },
    {
        id: '28',
        label: 'SpringRolls',
        name: 'Saumon',
        price: 6.10,
        nombre: 6,
        done: true,
    },
    {
        id: '29',
        label: 'SpringRolls',
        name: 'Thon',
        price: 6.20,
        nombre: 6,
        done: true,
    },
    {
        id: '30',
        label: 'SpringRolls',
        name: 'Crevette',
        price: 6.50,
        nombre: 6,
        done: true,
    },
    {
        id: '31',
        label: 'SpringRolls',
        name: 'Chicken Pané',
        price: 6.40,
        nombre: 6,
        done: true,
    },
    {
        id: '32',
        label: 'SpringRolls',
        name: 'Végétarien',
        price: 5.50,
        nombre: 6,
        done: true,
    },
    {
        id: '33',
        label: 'SpringRolls',
        name: 'Crevette Tempura',
        price: 6.90,
        nombre: 6,
        done: true,
    },
    {
        id: '34',
        label: 'BengoshiRolls',
        name: 'Saumon',
        price: 6.70,
        nombre: 6,
        done: true,
    },
    {
        id: '35',
        label: 'BengoshiRolls',
        name: 'Thon',
        price: 6.80,
        nombre: 6,
        done: true,
    },
    {
        id: '36',
        label: 'BengoshiRolls',
        name: 'Saumon fumé cheese',
        price: 6.70,
        nombre: 6,
        done: true,
    },
    {
        id: '37',
        label: 'BengoshiRolls',
        name: 'Crevette',
        price: 6.80,
        nombre: 6,
        done: true,
    },
    {
        id: '38',
        label: 'SaveurRolls',
        name: 'Tora',
        price: 9.40,
        nombre: 8,
        done: true,
    },
    {
        id: '39',
        label: 'SaveurRolls',
        name: 'Dragon',
        price: 9.40,
        nombre: 8,
        done: true,
    },
    {
        id: '40',
        label: 'SaveurRolls',
        name: 'Mexicain',
        price: 8.80,
        nombre: 8,
        done: true,
    },
    {
        id: '41',
        label: 'SaveurRolls',
        name: 'Japon Roll',
        price: 6.50,
        nombre: 8,
        done: true,
    },
    {
        id: '42',
        label: 'SaveurRolls',
        name: 'Le Saveur',
        price: 9.40,
        nombre: 8,
        done: true,
    },
    {
        id: '43',
        label: 'SaveurRolls',
        name: 'Oritsu',
        price: 10.40,
        nombre: 8,
        done: true,
    },
    {
        id: '44',
        label: 'SaveurRolls',
        name: 'Nekkyo',
        price: 8.90,
        nombre: 8,
        done: true,
    },
    {
        id: '45',
        label: 'SaveurRolls',
        name: 'Kanpeki',
        price: 10.90,
        nombre: 8,
        done: true,
    },
    {
        id: '46',
        label: 'HotMaki',
        name: 'Saumon',
        price: 6.60,
        nombre: 6,
        done: true,
    },
    {
        id: '47',
        label: 'HotMaki',
        name: 'Thon Spicy',
        price: 6.80,
        nombre: 6,
        done: true,
    },
    {
        id: '48',
        label: 'HotMaki',
        name: 'Crevette',
        price: 6.70,
        nombre: 6,
        done: true,
    },
    {
        id: '49',
        label: 'HotMaki',
        name: 'Cheese',
        price: 5.00,
        nombre: 6,
        done: true,
    },
    {
        id: '50',
        label: 'EggMaki',
        name: 'Thon Cuit',
        price: 4.70,
        nombre: 6,
        done: true,
    },
    {
        id: '51',
        label: 'EggMaki',
        name: 'Poulet',
        price: 4.20,
        nombre: 6,
        done: true,
    },
    {
        id: '52',
        label: 'EggMaki',
        name: 'Cheese',
        price: 4.00,
        nombre: 6,
        done: true,
    },
    {
        id: '53',
        label: 'Sashimi & Tataki',
        name: 'Saumon',
        price: 13.00,
        nombre: 1,
        done: true,
    },
    {
        id: '54',
        label: 'Sashimi & Tataki',
        name: 'Thon',
        price: 14.00,
        nombre: 1,
        done: true,
    },
    {
        id: '55',
        label: 'Sashimi & Tataki',
        name: 'Saumon/Thon',
        price: 13.50,
        nombre: 1,
        done: true,
    },
    {
        id: '56',
        label: 'Sashimi & Tataki',
        name: 'Saumon/Thon/Crevette',
        price: 14.00,
        nombre: 1,
        done: true,
    },
    {
        id: '57',
        label: 'Yakitori',
        name: 'Saumon',
        price: 4.70,
        nombre: 2,
        done: true,
    },
    {
        id: '58',
        label: 'Yakitori',
        name: 'Poulet',
        price: 4.00,
        nombre: 2,
        done: true,
    },
    {
        id: '59',
        label: 'Yakitori',
        name: 'Boeuf-fromage',
        price: 5.00,
        nombre: 2,
        done: true,
    },
    {
        id: '60',
        label: 'Yakitori',
        name: 'Gambas',
        price: 4.70,
        nombre: 2,
        done: true,
    },
    {
        id: '61',
        label: 'Tartare & Bowls',
        name: 'Saumon Avocat',
        price: 11.90,
        nombre: 1,
        done: true,
    },
    {
        id: '62',
        label: 'Tartare & Bowls',
        name: 'Thon Avocat',
        price: 13.90,
        nombre: 1,
        done: true,
    },
    {
        id: '63',
        label: 'Tartare & Bowls',
        name: 'Saumon ou Poulet',
        price: 12.40,
        nombre: 1,
        done: true,
    },
    {
        id: '64',
        label: 'Tartare & Bowls',
        name: 'Végétarien',
        price: 10.40,
        nombre: 1,
        done: true,
    },
    {
        id: '65',
        label: 'Accompagnements',
        name: 'Soupe miso',
        price: 2.90,
        nombre: 1,
        done: true,
    },
    {
        id: '66',
        label: 'Accompagnements',
        name: 'Edamame',
        price: 3.90,
        nombre: 1,
        done: true,
    },
    {
        id: '67',
        label: 'Accompagnements',
        name: 'Riz nature',
        price: 2.50,
        nombre: 1,
        done: true,
    },
    {
        id: '68',
        label: 'Accompagnements',
        name: 'Riz vinaigre',
        price: 2.90,
        nombre: 1,
        done: true,
    },
    {
        id: '69',
        label: 'Accompagnements',
        name: 'Salade d\'algue',
        price: 4.50,
        nombre: 1,
        done: true,
    },
    {
        id: '70',
        label: 'Accompagnements',
        name: 'Salade de calamars',
        price: 4.90,
        nombre: 1,
        done: true,
    },
    {
        id: '71',
        label: 'Accompagnements',
        name: 'Salade de choux',
        price: 2.90,
        nombre: 1,
        done: true,
    },
    {
        id: '72',
        label: 'Accompagnements',
        name: 'Salade de choux crevette',
        price: 4.50,
        nombre: 1,
        done: true,
    },
    {
        id:  '73',
        label: 'Accompagnements',
        name: 'Salade de choux saumon',
        price: 4.50,
        nombre: 1,
        done: true,
    },
    {
        id: '74',
        label: 'Accompagnements',
        name: 'Crevette tempura',
        price: 5.50,
        nombre: 3,
        done: true,
    },
    {
        id: '75',
        label: 'Maki Nutella',
        name: 'Nutella',
        price: 3.90,
        nombre: 6,
        done: true,
    },
    {
        id: '76',
        label: 'Maki Nutella',
        name: 'Nutella fraise',
        price: 4.50,
        nombre: 6,
        done: true,
    },
    {
        id: '77',
        label: 'Maki Nutella',
        name: 'Nutella banane',
        price: 4.50,
        nombre: 6,
        done: true,
    },
    {
        id: '78',
        label: 'Maki Nutella',
        name: 'Supp M&MS',
        price: 0.50,
        nombre: 0,
        done: true,
    },
    {
        id: '79',
        label: 'Desserts',
        name: 'Tarte aux daim',
        price: 4.20,
        nombre: 1,
        done: true,
    },
    {
        id: '80',
        label: 'Desserts',
        name: 'Moelleux Choco',
        price: 3.80,
        nombre: 1,
        done: true,
    },
    {
        id: '81',
        label: 'Desserts',
        name: 'Cheese Cake',
        price: 3.50,
        nombre: 1,
        done: true,
    },
    {
        id: '82',
        label: 'Desserts',
        name: 'Ben&Jerrys',
        price: 3.80,
        nombre: 1,
        done: true,
    },
    {
        id: '83',
        label: 'Boissons',
        name: 'Coca-cola',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '84',
        label: 'Boissons',
        name: 'Coca-cola Zero',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '85',
        label: 'Boissons',
        name: 'Orangina',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '86',
        label: 'Boissons',
        name: 'Fuzetea Peche',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '87',
        label: 'Boissons',
        name: 'Fuzetea Mangue',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '88',
        label: 'Boissons',
        name: 'Fuzetea CitronVert',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '89',
        label: 'Boissons',
        name: 'Evian',
        price: 2.00,
        nombre: 1,
        done: true,
    },
    {
        id: '90',
        label: 'Boissons',
        name: 'San Pellegrino',
        price: 2.20,
        nombre: 1,
        done: true,
    },
    {
        id: '91',
        label: 'Menu & Coffret',
        name: 'Solo',
        price: 13.00,
        nombre: 16,
        done: true,
    },
    {
        id: '92',
        label: 'Menu & Coffret',
        name: 'Duo',
        price: 27.00,
        nombre: 26,
        done: true,
    },
    {
        id: '93',
        label: 'Menu & Coffret',
        name: 'California mix',
        price: 12.90,
        nombre: 16,
        done: true,
    },
    {
        id: '94',
        label: 'Menu & Coffret',
        name: 'Yakitori',
        price: 12.90,
        nombre: 6,
        done: true,
    },
    {
        id: '95',
        label: 'Menu & Coffret',
        name: 'Découverte',
        price: 35.00,
        nombre: 30,
        done: true,
    },
    {
        id: '96',
        label: 'Menu & Coffret',
        name: 'Saveur',
        price: 52.00,
        nombre: 48,
        done: true,
    },

    
];
